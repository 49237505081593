.react-grid-Row.row-selected:hover .react-grid-Cell {
  background-color: #dbecfa !important;
}

.react-grid-Row .idCell .buttons {
  display: none;
}

.react-grid-Row:hover .idCell .buttons {
  display: block;
}

.react-grid-Row:hover .idCell .idText {
  display: none;
}

.react-grid-HeaderCell {
  padding-top: 3px !important;
  font-weight: 500 !important;
}

.radio-custom + .radio-custom-label:before,
.react-grid-checkbox + .react-grid-checkbox-label:before {
  width: 15px !important;
  height: 15px !important;
}
